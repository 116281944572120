import project1image from '../../../assets/media/project-page-presets/1.png';
import project2image from '../../../assets/media/project-page-presets/2.png';
import project3image from '../../../assets/media/project-page-presets/3.png';
import project4image from '../../../assets/media/project-page-presets/4.png';
import project5image from '../../../assets/media/project-page-presets/5.png';
import project6image from '../../../assets/media/project-page-presets/6.png';
import project7image from '../../../assets/media/project-page-presets/7.png';
import { CollectionWidgetComponentIds } from '../../collectionWidget/config/constants';

export enum PortfolioPageWidgetComponentIds {
  Root = '#portfolioPageWidgetRoot',
  MultiStateWidget = '#collectionMsbWidget1',
}

export enum PortfolioPageWidgetEvents { }

export const PortfolioPageWidgetTabsArray = [
  {
    label: 'Portfolio_Design_Panel_Title',
    roles: [CollectionWidgetComponentIds.Title],
    skipOnEditorX: true,
  },
  {
    label: 'Portfolio_Design_Panel_Description',
    roles: [CollectionWidgetComponentIds.Description],
    skipOnEditorX: true,
  },
  // {
  //   label: 'Portfolio_Design_Panel_Projects_Gallery',
  //   roles: [CollectionWidgetComponentIds.Gallery],
  // },
  {
    label: 'Portfolio_Design_Panel_Page_Background',
    roles: [PortfolioPageWidgetComponentIds.Root],
  },
];

// temp use of collection/projects presets images till Liron add new ones
export const PortfolioPageWidgetPresets = [
  {
    id: 'externalPreset-variants-l7z94248',
    connectedMobilePresetID: 'variants-l2uitzc0',
    src: project1image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5hrjm',
    connectedMobilePresetID: 'variants-lbc5mkrs',
    src: project2image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5obj1',
    connectedMobilePresetID: 'variants-lbc5su2o',
    src: project3image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5v6mw',
    connectedMobilePresetID: 'variants-lbc5v1iy',
    src: project4image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5xfvq',
    connectedMobilePresetID: 'variants-lbc5xb9y',
    src: project5image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5z9yi',
    connectedMobilePresetID: 'variants-lbc5z4w7',
    src: project6image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc64w40',
    connectedMobilePresetID: 'variants-lbc64nay',
    src: project7image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc685rw',
    connectedMobilePresetID: 'variants-lbc67zat',
    src: project1image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc7z56y',
    connectedMobilePresetID: 'variants-lbc805kh',
    src: project2image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8143t',
    connectedMobilePresetID: 'variants-lbc81zj8',
    src: project3image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc82x7k',
    connectedMobilePresetID: 'variants-lbc83tfx',
    src: project4image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc84yzn',
    connectedMobilePresetID: 'variants-lbc896eb',
    src: project5image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8a4c1',
    connectedMobilePresetID: 'variants-lbc8axec',
    src: project6image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8buq0',
    connectedMobilePresetID: 'variants-lbc8epqe',
    src: project7image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8gbqm',
    connectedMobilePresetID: 'variants-lbc8hfa8',
    src: project1image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8iiiz',
    connectedMobilePresetID: 'variants-lbc8l4ze',
    src: project2image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8m4re',
    connectedMobilePresetID: 'variants-lbc8n8zv',
    src: project3image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8o9d8',
    connectedMobilePresetID: 'variants-lbc8tcmx',
    src: project4image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8ubcd',
    connectedMobilePresetID: 'variants-lbc8w03b',
    src: project5image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8x3im',
    connectedMobilePresetID: 'variants-lbc8yc96',
    src: project6image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8zgd8',
    connectedMobilePresetID: 'variants-lbc90m76',
    src: project6image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-lbc91ubo',
    connectedMobilePresetID: 'variants-lbc92ymt',
    src: project7image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
];

import collectionsPreset1 from '../../../assets/media/collections-page-presets/1.png';
import collectionsPreset2 from '../../../assets/media/collections-page-presets/2.png';
import collectionsPreset3 from '../../../assets/media/collections-page-presets/3.png';
import collectionsPreset4 from '../../../assets/media/collections-page-presets/4.png';
import collectionsPreset5 from '../../../assets/media/collections-page-presets/5.png';
import collectionsPreset6 from '../../../assets/media/collections-page-presets/6.png';
import collectionsPreset7 from '../../../assets/media/collections-page-presets/7.png';
import collectionsPreset8 from '../../../assets/media/collections-page-presets/8.png';
import collectionsPreset9 from '../../../assets/media/collections-page-presets/9.png';
import collectionsPreset10 from '../../../assets/media/collections-page-presets/10.png';
import collectionsPreset11 from '../../../assets/media/collections-page-presets/11.png';
import collectionsPreset12 from '../../../assets/media/collections-page-presets/12.png';
import collectionsPreset13 from '../../../assets/media/collections-page-presets/13.png';
import collectionsPreset14 from '../../../assets/media/collections-page-presets/14.png';
import collectionsPreset15 from '../../../assets/media/collections-page-presets/15.png';
import collectionsPreset16 from '../../../assets/media/collections-page-presets/16.png';
import collectionsPreset17 from '../../../assets/media/collections-page-presets/17.png';
import collectionsPreset18 from '../../../assets/media/collections-page-presets/18.png';
import collectionsPreset19 from '../../../assets/media/collections-page-presets/19.png';
import collectionsPreset20 from '../../../assets/media/collections-page-presets/20.png';
import collectionsPreset21 from '../../../assets/media/collections-page-presets/21.png';
import collectionsPreset22 from '../../../assets/media/collections-page-presets/22.png';
import collectionsPreset23 from '../../../assets/media/collections-page-presets/23.png';
import collectionsPreset24 from '../../../assets/media/collections-page-presets/24.png';

import { CollectionWidgetComponentIds } from '../../collectionWidget/config/constants';
import { NavigationWidgetComponentIds } from '../../navigationWidget/config/constants';

export enum CollectionPageWidgetComponentIds {
  Root = '#collectionPageWidgetRoot',
  CollectionWidget = '#collectionWidget',
  NavigationWidget = '#navigationWidget',
  EmptyStateWidget = '#collectionEmptyStateWidget1',
}
export enum CollectionPageWidgetEvents { }

export const CollectionPageWidgetTabsArray = [
  {
    label: 'Collection_Design_Panel_Title',
    roles: [CollectionWidgetComponentIds.Title],
    skipOnEditorX: true,
  },
  {
    label: 'Collection_Design_Panel_Description',
    roles: [CollectionWidgetComponentIds.Description],
    skipOnEditorX: true,
  },
  // {
  //   label: 'Collection_Design_Panel_Projects_Gallery',
  //   roles: [CollectionWidgetComponentIds.Gallery],
  // },
  {
    label: 'Collection_Design_Panel_Back_Button',
    roles: [NavigationWidgetComponentIds.BackButtonWidget],
    skipOnEditorX: true,
  },
  {
    label: 'Collection_Design_Panel_Page_Background',
    roles: [CollectionPageWidgetComponentIds.Root],
    skipOnEditorX: true,
  },
];

// temp use of project presets images till Liron add new ones
export const CollectionPageWidgetPresets = [
  {
    id: 'externalPreset-variants-lbxcjmbu',
    connectedMobilePresetID: 'variants-lbxcklxe',
    src: collectionsPreset1,
    layout: {
      width: '114px',
      height: '102px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5hrjm',
    connectedMobilePresetID: 'variants-lbc5mkrs',
    src: collectionsPreset2,
    layout: {
      width: '114px',
      height: '102px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5xfvq',
    connectedMobilePresetID: 'variants-lbc5xb9y',
    src: collectionsPreset3,
    layout: {
      width: '114px',
      height: '123px',
    },
  },
  {
    id: 'externalPreset-variants-lbc685rw',
    connectedMobilePresetID: 'variants-lbc67zat',
    src: collectionsPreset4,
    layout: {
      width: '114px',
      height: '123px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5v6mw',
    connectedMobilePresetID: 'variants-lbc5v1iy',
    src: collectionsPreset5,
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lbc64w40',
    connectedMobilePresetID: 'variants-lbc64nay',
    src: collectionsPreset6,
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8buq0',
    connectedMobilePresetID: 'variants-lbc8epqe',
    src: collectionsPreset7,
    layout: {
      width: '114px',
      height: '108px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5z9yi',
    connectedMobilePresetID: 'variants-lbc5z4w7',
    src: collectionsPreset8,
    layout: {
      width: '114px',
      height: '108px',
    },
  },
  {
    id: 'externalPreset-variants-lbc82x7k',
    connectedMobilePresetID: 'variants-lbc83tfx',
    src: collectionsPreset9,
    layout: {
      width: '114px',
      height: '98px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8143t',
    connectedMobilePresetID: 'variants-lbc81zj8',
    src: collectionsPreset10,
    layout: {
      width: '114px',
      height: '98px',
    },
  },
  {
    id: 'externalPreset-variants-lbc7z56y',
    connectedMobilePresetID: 'variants-lbc805kh',
    src: collectionsPreset11,
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8a4c1',
    connectedMobilePresetID: 'variants-lbc8axec',
    src: collectionsPreset12,
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8gbqm',
    connectedMobilePresetID: 'variants-lbc8hfa8',
    src: collectionsPreset13,
    layout: {
      width: '114px',
      height: '121px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5obj1',
    connectedMobilePresetID: 'variants-lbc5su2o',
    src: collectionsPreset14,
    layout: {
      width: '114px',
      height: '121px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8iiiz',
    connectedMobilePresetID: 'variants-lbc8l4ze',
    src: collectionsPreset15,
    layout: {
      width: '114px',
      height: '96px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8m4re',
    connectedMobilePresetID: 'variants-lbc8n8zv',
    src: collectionsPreset16,
    layout: {
      width: '114px',
      height: '96px',
    },
  },
  {
    id: 'externalPreset-variants-lbxc0siz',
    connectedMobilePresetID: 'variants-lbxc1tvv',
    src: collectionsPreset17,
    layout: {
      width: '114px',
      height: '97px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8ubcd',
    connectedMobilePresetID: 'variants-lbc8w03b',
    src: collectionsPreset18,
    layout: {
      width: '114px',
      height: '97px',
    },
  },
  {
    id: 'externalPreset-variants-lbc91ubo',
    connectedMobilePresetID: 'variants-lbc92ymt',
    src: collectionsPreset19,
    layout: {
      width: '114px',
      height: '123px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8x3im',
    connectedMobilePresetID: 'variants-lbc8yc96',
    src: collectionsPreset20,
    layout: {
      width: '114px',
      height: '123px',
    },
  },
  {
    id: 'externalPreset-variants-lbxbxc2r',
    connectedMobilePresetID: 'variants-lbxby647',
    src: collectionsPreset21,
    layout: {
      width: '114px',
      height: '85px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8o9d8',
    connectedMobilePresetID: 'variants-lbc8tcmx',
    src: collectionsPreset22,
    layout: {
      width: '114px',
      height: '85px',
    },
  },
  {
    id: 'externalPreset-variants-lbc84yzn',
    connectedMobilePresetID: 'variants-lbc896eb',
    src: collectionsPreset23,
    layout: {
      width: '114px',
      height: '101px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8zgd8',
    connectedMobilePresetID: 'variants-lbc90m76',
    src: collectionsPreset24,
    layout: {
      width: '114px',
      height: '101px',
    },
  },
];
